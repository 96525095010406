import React, { memo } from 'react'
// import HomeContent from '../components/HomeContent/HomeContent'
import DSELayout from '../components/DSE/DSELayout/DSELayout';
import DSEWrapper from '../components/DSE/DSEWrapper/DSEWrapper';
import DseHero from '../components/DSE/DSEhero/DSEhero';
import WhatNpc from '../components/DSE/WhatNpc/WhatNpc';
import CauseNpc from '../components/DSE/CauseNpc/CauseNpc';
import HowDiagnosed from '../components/DSE/HowDiagnosed/HowDiagnosed';
import HowTreated from '../components/DSE/HowTreated/HowTreated';
import Resources from '../components/DSE/Resources/Resources';
import Glossary from '../components/DSE/Glossary/Glossary';
import FaceParts from '../components/DSE/FaceParts/FaceParts';
import SignsOfNPC from '../components/DSE/SignsOfNPC/SignsOfNPC';

/* As you probably know, Gatsby makes it very easy to route the project.  You simply need to add the .js or .jsx file To the pages directory and then the name of the page becomes the route.  However, in order to organize the project Properly, you would typically put the .jsx file and the accompanying .scss file into it's own directory.  Doing That for the pages, would create a double directory for the route.  For example, /home/home.  As a result, For organization purposes, I have placed the pages here and simply returned the accompanying, 'Content' jsx. The content jsx and scss are organized as they're supposed to be, within there own directory and that is theWhere the content for each page really begins.
 */

const Home = props => {
  return (
    <React.Fragment>
      <DSELayout 
        canonicalURL='https://www.npcfacts.com/'
        title='Understanding Nasopharyngeal Cancer (NPC) | NPC.com'
        pageTitle='Understanding Nasopharyngeal Cancer (NPC) | NPC.com'
        description='Site aimed to help you better understand the Nasopharyhngeal Cancer (NPC) and the options for treating it.'
        keyWords='Nasopharyngeal carcinoma; NPC treatments; NPC outcomes'
        >

          <DSEWrapper bgClass="hero" fullWidth={true}>
            <DseHero />
          </DSEWrapper>
          <FaceParts />
          <WhatNpc />
          <CauseNpc />
          <SignsOfNPC />
          <HowDiagnosed />
          <HowTreated />
          <Resources />
          <Glossary />
      </DSELayout>
    </React.Fragment>
  )
}

export default memo(Home)
