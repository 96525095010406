import React from "react";
import DSEWrapper from "../DSEWrapper/DSEWrapper";
import Title from "../title/title";
import IconTitleText from "../IconTitleText/IconTitleText";
import DseCta from "../DSECta/DSECta";

const Resources = () => {
  return (
    <section className="dse-resources dse-grad-bg5" id="resources">
      <DSEWrapper className="dse-resources__title" fullWidth={true}>
        <Title text="RESOURCES"/>
      </DSEWrapper>
      <DSEWrapper>
        <p className="dse-resources__instructions">Below is a list of patient advocacy groups that are committed to providing emotional support and medical information to help guide you on your NPC journey. </p>
        <div className="dse-resources__list">
          <IconTitleText title="SUPPORT FOR HEAD AND NECK CANCER">
            <ul>
              <li>Head and Neck Cancer Alliance</li>
              <li>Support for People With Oral Head and Neck Cancer (SPOHNC)</li>
              <li>Thyroid Head and Neck Cancer Foundation (THANC)</li>
            </ul>
          </IconTitleText>
          <IconTitleText title="SUPPORT FOR ALL CANCERS">
            <ul>
              <li>Cancer Care</li>
              <li>Cancer Support Community</li>
              <li>American Cancer Society</li>
              <li>National Organization for Rare Disorders</li>
              <li>National Comprehensive Cancer Network (NCCN) Foundation</li>
              <li>Triage Cancer</li>
            </ul>
          </IconTitleText>
        </div>
        <div className="dse-resources__download">
          <p className="dse-resources__highlight dse-highlight-text">Read a helpful brochure about understanding NPC</p>
          <DseCta className="dse-resources__cta" type="link" href="/pdf/npc-dse-brochure.pdf" target="_blank" text="DOWNLOAD"/>
        </div>
        <div className="dse-resources__download mt-big">
          <p className="dse-resources__highlight dse-highlight-text">Get a guide to help you talk with your doctor about NPC</p>
          <DseCta className="dse-resources__cta" type="link" href="/pdf/npc-doctor-discussion-guide.pdf" target="_blank" text="DOWNLOAD"/>
        </div>
      </DSEWrapper>
    </section>
  )
};

export default Resources;