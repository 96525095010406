import React, { useEffect, useState } from 'react';
import * as styles from './SoundTest.module.scss';
import clsx from 'clsx';
import FrequencySlider from '../../FrequencySlider/FrequencySlider';
import DseCta from '../DSECta/DSECta';
import FrequencyImg from "../../../assets/images/pages/dse-home/frequenciesWithText.inline.svg";
import FrequencyDesktopImg from "../../../assets/images/pages/dse-home/frequenciesWithText-d.inline.svg";
import SoundBalance from '../../SoundBalance/SoundBalance';

let cntx;
const beat_freq = 10;
let AudioContext = (typeof window !== 'undefined') && (window.AudioContext || window.webkitAudioContext);
let merger;
let volumeNode;
const rampTime = 1000; // time in milliseconds for the sounds to fade in/out
let timerId = 0;
const initialFrequency = 3000;

export default function SoundTest({ className }) {
  const [isVolumeSteady, setIsVolumeSteady] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(.003);
  const [leftOsc, setLeftOsc] = useState(null);
  const [rightOsc, setRightOsc] = useState(null);
  const [frequency, setFrequency] = useState(initialFrequency);
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    //console.log('changing frequency => ', frequency);
    if(balance === -1) {
      if(leftOsc) leftOsc.frequency.value = frequency + beat_freq;
    } else if(balance == 1){
      if(rightOsc) rightOsc.frequency.value = frequency;
    } else {
      if(leftOsc) leftOsc.frequency.value = frequency + beat_freq;
      if(rightOsc) rightOsc.frequency.value = frequency;
    }
  }, [frequency]);

  useEffect(() => {
    //console.log('changing balance => ', balance);
    isPlaying && startPlaying();
  }, [balance]);

  function toggleSound() {
    if (isVolumeSteady) {
      if (isPlaying) {
        setIsPlaying(false);
        fadeOut();
        rightOsc && rightOsc.stop();
        leftOsc && leftOsc.stop();
      } else {
        startPlaying();
      }
    }
  }

  function startPlaying() {
    createAudioContext();
    startBalancedSound();
    if (!isPlaying) {
      fadeUp();
      setIsPlaying(true);
    }
  }

  function createAudioContext() {
    if(window) {
      //console.log('Creating audio context');
      if(cntx) {
        cntx.close();
      }
      cntx = new AudioContext();
      merger = cntx.createChannelMerger(2);
      volumeNode = cntx.createGain();
      volumeNode.gain.value = volume;

      merger.connect(volumeNode);
      volumeNode.connect(cntx.destination);
    }
  }

  function startBalancedSound() {
    if(balance === -1) {
      setLeftSound();
      rightOsc && rightOsc.stop();
    } else if(balance === 1) {
      setRightSound();
      leftOsc && leftOsc.stop();
    } else {
      leftOsc && leftOsc.stop();
      rightOsc && rightOsc.stop();
      setLeftSound();
      setRightSound();
    }
  }

  function setLeftSound() {
      //the oscillators are created here because once they are stopped they can not
      //be started again.
      const left_osc = cntx.createOscillator();
      left_osc.frequency.value = frequency + beat_freq;
      left_osc.connect(merger, 0, 0);
      left_osc.start();
      setLeftOsc(left_osc);
  }

  function setRightSound() {
      //the oscillators are created here because once they are stopped they can not
      //be started again.
      const right_osc = cntx.createOscillator();
      right_osc.frequency.value = frequency;
      right_osc.connect(merger, 0, 1);
      right_osc.start();
      setRightOsc(right_osc);
  }

  function fadeOut() {
    setIsVolumeSteady(false);
    const volumeStepAmount = volume / (rampTime / 10);
    //console.log(volumeStepAmount);

    timerId = setInterval(() => {
      volumeNode.gain.value = volumeNode.gain.value - volumeStepAmount;
      //console.log('volumeNode.gain.value -> ', volumeNode.gain.value);
      if (volumeNode.gain.value <= 0) {
        clearInterval(timerId);
        rightOsc && rightOsc.stop();
        leftOsc && leftOsc.stop();
        setIsVolumeSteady(true);
      }
    }, 10);
  }

  function fadeUp() {
    setIsVolumeSteady(false);
    const volumeStepAmount = volume / (rampTime / 10);
    //console.log(volumeStepAmount);

    timerId = setInterval(() => {
      volumeNode.gain.value = volumeNode.gain.value + volumeStepAmount;
      //console.log('volumeNode.gain.value -> ', volumeNode.gain.value);
      if (volumeNode.gain.value >= volume) {
        clearInterval(timerId);
        setIsVolumeSteady(true);
      }
    }, 10);
  }

  function handleFrequencyChange(val) {
    setFrequency(Number(val[0]));
  }

  function handleSoundBalanceChange(val) {
    setBalance(val);
  }

  return (
    <section className={ clsx(styles.soundTest, className) } id="npc-sound-test">
      <h3 className={clsx(styles.title, 'dse-heading3 dse-light-text')}>NPC Sound Check</h3>
      <p className={styles.highlight}>Hearing loss could be due to many common issues. In rare cases, it may indicate something more serious: nasopharyngeal cancer, or NPC, a form of head and neck cancer.</p>
      <p className={styles.highlight}>People with NPC commonly experience hearing impairment because sound waves may encounter obstacles in the middle ear due to tumor growth. People often report having symptoms in one ear and trouble hearing higher frequencies, specifically between <span className='nowrap'>3500 Hz</span> and <span className='nowrap'>6000 Hz</span>.</p>

      <p><span className={ styles.underline }>How to use this sound check</span></p>
      <ul className={ styles.list }>
        <li>For an optimal experience:
          <ul className={ styles.subList }>
            <li>Put on your headphones</li>
            <li>Turn off noise cancelling</li>
            <li>Raise device volume to 100%</li>
          </ul>
        </li>
        <li>Make sure you’re in a quiet environment</li>
        <li>Click the button to start/stop the tone</li>
      </ul>
      <DseCta className={ clsx(styles.cta, isPlaying && styles.playingButton) } action={ toggleSound } text={isPlaying ? "STOP" : "START"} title=""/>

    <div className={ styles.frequency }>
      <FrequencyImg className={ styles.frequencyMobile }/>
      <FrequencyDesktopImg className={ styles.frequencyDesktop }/>

      <FrequencySlider className={ styles.frequencySlider } min={0} max={6000} initialValue={ [initialFrequency] } onChange={handleFrequencyChange}/>
      <p className={ styles.subtitle }>Frequency ({ frequency } Hz)</p>
      <p className={ styles.indication }>Slide the toggle from left to right to check your ability to hear sounds at different frequencies. </p>
    </div>

    <div className={ styles.soundBalance }>
      <SoundBalance balance={balance} onChange={handleSoundBalanceChange}/>

      <p className={ clsx (styles.subtitle, styles.balanceSection) }>Balance</p>
      <p className={ styles.indication }>Select what you want to check: left ear, right ear, or both.</p>
    </div>

    <div className={ styles.disclaimer }>
      <p>If you’re having trouble hearing frequencies between <span className='nowrap'>3500 Hz</span> and <span className='nowrap'>6000 Hz</span>, you should speak with your doctor or a hearing care professional.</p>
      <p>Only a hearing test performed by a qualified hearing care professional can provide you with a precise profile of your hearing ability. This test is not intended to replace a professional hearing test from
    a qualified hearing care professional.</p>
    </div>
    </section>
  )
}

