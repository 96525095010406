import React from "react";
import { navigate } from "gatsby"
import Title from "../title/title";
import DSEWrapper from "../DSEWrapper/DSEWrapper";
import IconTitleText from "../IconTitleText/IconTitleText";
import Ribbon from "../ribbon/ribbon";
import DseCta from "../DSECta/DSECta";
import icon1 from "../../../assets/images/pages/dse-home/types-icon1.svg";
import icon2 from "../../../assets/images/pages/dse-home/types-icon2.svg";
import icon3 from "../../../assets/images/pages/dse-home/types-icon3.svg";
import fact1 from "../../../assets/images/pages/dse-home/fact-icon1.svg";
import fact2 from "../../../assets/images/pages/dse-home/fact-icon2.svg";
import fact3 from "../../../assets/images/pages/dse-home/fact-icon3.svg";
import fact4 from "../../../assets/images/pages/dse-home/fact-icon4.svg";

const WhatNpc = () => {
  const clickHandler = () => {
    navigate('/join-the-community');
  };
  return (
    <section className="dse-what-npc dse-grad-bg1" id="what-is-npc">
      <DSEWrapper className="dse-what-npc__title" fullWidth={true}>
        <Title text="WHAT IS NPC?"/>
      </DSEWrapper>
      <DSEWrapper>
        <div className="dse-what-npc__intro">
          <div className="dse-what-npc__intro-text">
            <p>NPC starts in the nasopharynx, which is located at the upper part of the throat behind the nose and near the base of the skull.</p>
            <p>NPC can come back at or near the original tumor after being treated (recurrent locally advanced) or spread to other areas of the body (metastatic). This is also known as R/M NPC.</p>
          </div>
          <IconTitleText title="Recurrent locally <br/> advanced NPC">
            <p>The cancer has come back at or near the same place as the original tumor.</p>
          </IconTitleText>
          <IconTitleText title="Metastatic NPC">
            <p>The cancer has spread to other areas of the body.</p>
          </IconTitleText>
        </div>
        <div className="dse-what-npc__types">
          <h3 className="dse-heading3 dse-light-text">THE 3 MAIN TYPES OF NPC</h3>
          <div className="dse-what-npc__types-list">
            <IconTitleText icon={icon1} title="Keratinizing<br/>squamous<br/>cell cancer" iconAlt="The image illustrates the Keratinizing Squamous Cell cancer Nasopharyngeal Cancer type 1">
              <p><strong>Keratinizing</strong> cells produce an excess amount of protein called keratin. Keratin hardens the outermost layer of the tumor. </p>
              <p><strong>Both types 2 and 3 are nonkeratinizing</strong>, which means hardening does not happen.</p>
            </IconTitleText>
            <IconTitleText icon={icon2} title="Differentiated<br/>nonkeratinizing<br/>cell cancer" iconAlt="The image illustrates Differentiated Nonkeratinizing Cell cancer type 2">
              <p><strong>Differentiated</strong> cells tend to grow and spread <em>slowly</em> to other parts of the body.</p>
            </IconTitleText>
            <IconTitleText icon={icon3} title="unDifferentiated<br/>nonkeratinizing<br/>cell cancer" iconAlt="The image illustrates Undifferentiated Nonkeratinizing Cell cancer type 3">
              <p><strong>Undifferentiated</strong> cells tend to grow and spread <em>quickly</em> to other parts of the body.</p>
            </IconTitleText>
          </div>
          <Ribbon>
            <p>Regardless of which type of NPC you have, the treatment may be  the same</p>
          </Ribbon>
        </div>
        <div className="dse-what-npc__did-know">
          <h3 className="dse-heading3 dse-light-text">Did you know?</h3>
          <div className="dse-what-npc__did-know-list">
            <IconTitleText icon={fact1} iconAlt="Types 1 and 3 are the most common forms of NPC in the United States.">
              <p><strong>Types 1 and 3 are the most common</strong> forms of NPC in the United States.</p>
            </IconTitleText>
            <IconTitleText icon={fact2} iconAlt="In the United States, the average age of diagnosis for NPC is 53 years.">
              <p>In the United States, the <strong>average age of diagnosis</strong> for NPC is <strong>53 years</strong>.</p>
            </IconTitleText>
            <IconTitleText icon={fact3} iconAlt="The risk of NPC increases with age, but it can occur at any stage of life, including childhood.">
              <p><strong>The risk of NPC increases with age</strong>, but it can occur at any stage of life, including childhood. </p>
            </IconTitleText>
            <IconTitleText icon={fact4} iconAlt="The risk of NPC is 2-3x more likely in men than in women.">
              <p>The risk of <strong>NPC is 2-3x more likely in men</strong> than in women. </p>
            </IconTitleText>
          </div>
          <p className="dse-highlight-text">Discover more NPC facts by joining the community</p>
          <DseCta action={clickHandler} text="Join now"/>
        </div>
      </DSEWrapper>
    </section>
  )
}

export default WhatNpc;