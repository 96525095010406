import React from "react";
import { navigate } from "gatsby"
import Title from "../title/title";
import IconTitleText from "../IconTitleText/IconTitleText";
import DseCta from "../DSECta/DSECta";
import DSEWrapper from "../DSEWrapper/DSEWrapper";
import icon1 from "../../../assets/images/pages/dse-home/how-icon1.svg";
import icon2 from "../../../assets/images/pages/dse-home/how-icon2.svg";
import SoundTest from '../../DSE/SoundTest/SoundTest';

const HowDiagnosed = () => {
  const clickHandler = () => {
    navigate('/join-the-community');
  };

  return (
    <section className="dse-how-diagnosed dse-grad-bg4" id="how-is-npc-diagnosed">
      <DSEWrapper className="dse-how-diagnosed__title" fullWidth={true}>
        <Title text="HOW IS NPC DIAGNOSED?"/>
      </DSEWrapper>
      <DSEWrapper className="dse-how-diagnosed__causes">
        <p className="dse-how-diagnosed__subtitle dse-light-text">In order to diagnose NPC, your healthcare provider may consider the following:</p>
        <div className="dse-how-diagnosed__causes-list">
          <IconTitleText icon={icon1} title="Medical history" iconAlt="The image shows how Nasopharyngeal cancer is diagnosed">
            <p>When you meet with a healthcare professional, they may ask about your health and medical history.</p>
            <p>They may also ask you about your family’s medical history to determine whether that could be a factor.</p>
          </IconTitleText>
          <IconTitleText icon={icon2} title="Exam" iconAlt="The image illustrates the medical procedure to diagnose Nasopharyngeal cancer">
            <p>Your healthcare provider will ask about any symptoms you are having.</p>
            <p>They will examine you to look for signs of NPC and may conduct additional tests to better understand your condition and its effects. These may include:</p>
            <ul>
              <li>Neurological exam</li>
              <li>Radiologic study (PET scan, CT scan, MRI, and/or ultrasound)</li>
              <li>Endoscopy or nasopharyngoscopy</li>
              <li>Biopsy/lab tests</li>
              <li>Hearing test</li>
            </ul>
          </IconTitleText>
        </div>
        <SoundTest />
        <p className="dse-highlight-text dse-how-diagnosed__discover-more">Discover more about how NPC is diagnosed</p>
        <DseCta action={clickHandler} text="Join the community"/>
      </DSEWrapper>
    </section>
  )
};

export default HowDiagnosed;