import React from "react";
import DSEWrapper from "../DSEWrapper/DSEWrapper";
import Title from "../title/title";
import GlossaryItem from "../GlossaryItem/GlossaryItem";
import GlossaryList from "../../../data/glossary";

const Glossary = () => {
  let half1 = [];
  let half2 = [];
  GlossaryList.map((item, i) => {
    i <= Math.floor(GlossaryList.length/2) ? half1.push(item) : half2.push(item);
  })
  const buildGlossaryList = (list) => {
    return list.map((item, i) => {
      return (
        <GlossaryItem id={item.id} key={i} title={item.title} audio={item.audio}>
          <p>{`${item.text}`}</p>
        </GlossaryItem>
      )
    });
  }
  return (
    <section className="dse-glossary dse-grad-bg7">
        <DSEWrapper className="dse-glossary__title" fullWidth={true}>
          <Title text="GLOSSARY"/>
        </DSEWrapper>
        <DSEWrapper>
        <div className="dse-glossary__list">
          <div className="dse-glossary__list-col">
            {buildGlossaryList(half1)}
          </div>
          <div className="dse-glossary__list-col">
            {buildGlossaryList(half2)}
          </div>
        </div>
        </DSEWrapper>
    </section>
  )
}
  
export default Glossary;