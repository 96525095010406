import React from 'react';
import DseCta from '../DSECta/DSECta';
import { navigate } from 'gatsby';

const DseHero = ({ className }) => {
  const clickHandler = () => {
    navigate('/join-the-community');
  }

  return (
    <section className={ `dse-hero ${className || ''}` }>
      <div className='dse-hero__content'>
        <h1 className='dse-main-title dse-hero__title'>
          <span className='dse-main-title__highlight'>Understanding</span>
          <br/>
          nasopharyngeal
          <br/>cancer (NPC)
        </h1>
        <div className='dse-hero__text'>
          <p>A diagnosis of NPC <br className='d-md-none'/>can be overwhelming.</p>
          <br/>
          <p>The information on this site <br className='d-md-none'/>
            may help you better <br className='d-md-none'/>
            understand <br className='d-none d-md-block'/>the disease and <br className='d-md-none'/>
            the options you may have <br className='d-md-none'/>
            for treating it.</p>
        </div>
        <DseCta className='dse-hero__cta' text="JOIN THE COMMUNITY" action={clickHandler}/>
      </div>
    </section>
  )
}

export default DseHero
