import biopsyAudio from '../assets/audios/Biopsy_48k_stereo_edited.mp3';
import chemotherapyAudio from '../assets/audios/BL-0002_Chemotherapy_48k_stereo.mp3';
import tomographyAudio from '../assets/audios/BL-0003_Computerized-tomography-scan_48k_stereo.mp3';
import epsteinAudio from '../assets/audios/BL-0004_Epstein-barr-virus_48k_stereo.mp3';
import headNeckAudio from '../assets/audios/BL-0005_Head-and-neck-cancer_48k_stereo.mp3';
import papillomaAudio from '../assets/audios/BL-0006_Human-papillomavirus_48k_stereo.mp3';
import immunotherapyAudio from '../assets/audios/BL-0007_Immunotherapy_48k_stereo.mp3';
import imrAudio from '../assets/audios/BL-0008_Intensity-modulated-radiation_48k_stereo.mp3';
import metastasisAudio from '../assets/audios/BL-0009_Metastasis_48k_stereo.mp3';
import resonanceAudio from '../assets/audios/BL-0010_Magnetic-resonance-imaging_48k_stereo.mp3';
import positronEmissionAudio from '../assets/audios/BL-0011_Positron-emission-tomography-s_48k_stereo.mp3';
import radiationAudio from '../assets/audios/BL-0012_Radiation-therapy_48k_stereo.mp3';
import squamosAudio from '../assets/audios/BL-0013_Squamous-cell_48k_stereo.mp3';

const GlossaryList = [
  {
    id: 'glossary-biopsy',
    title: 'Biopsy',
    audio: biopsyAudio,
    text: 'The removal of cells or tissues for the purpose of pathologist examination and testing.'
  },
  {
    id: 'glossary-chemotherapy',
    title: 'Chemotherapy',
    audio: chemotherapyAudio,
    text: 'Medication used to stop the growth of cancer cells. It may be used alone or in combination with other treatments.'
  },
  {
    id: 'glossary-computerized',
    title: 'Computerized Tomography scan (ct)',
    audio: tomographyAudio,
    text: 'An imaging test that uses X-rays linked to a computer to create detailed 3D images of tissues and organs from different angles inside the body.'
  },
  {
    id: 'glossary-epstein',
    title: 'Epstein-Barr virus (EBV) ',
    audio: epsteinAudio,
    text: 'A common virus remaining inactive in most people. EBV is associated with certain cancers such as NPC, specifically types 2 and 3.'
  },
  {
    id: 'glossary-head-neck',
    title: 'Head and neck cancer',
    audio: headNeckAudio,
    text: 'Cancer that presents in the head or neck region (areas such as the nasal cavity, sinuses, throat, voice box, mouth, or salivary glands).'
  },
  {
    id: 'glossary-hpv',
    title: 'Human papillomavirus (HPV) ',
    audio: papillomaAudio,
    text: 'The most common sexually transmitted infection. HPV is associated with type 1 NPC.'
  },
  {
    id: 'glossary-immunotherapy',
    title: 'Immunotherapy',
    audio: immunotherapyAudio,
    text: 'Therapy that uses a person’s own immune system to help the body fight cancer.'
  },
  {
    id: 'glossary-imrt',
    title: 'Intensity-modulated radiation therapy ',
    audio: imrAudio,
    text: 'Highly precise therapy that delivers radiation to a tumor and produces 3D images of the size and shape of the tumor onto a computer screen.'
  },
  {
    id: 'glossary-metastasis',
    title: 'Metastasis',
    audio: metastasisAudio,
    text: 'The spread of cancer from the place where it started to other parts of the body.'
  },
  {
    id: 'glossary-mri',
    title: 'Magnetic resonance imaging (MRI) ',
    audio: resonanceAudio,
    text: 'An imaging technique that uses radio waves and magnetic fields linked to a computer to create detailed images of areas inside the body. These images show the difference between normal and diseased tissue. '
  },
  {
    id: 'glossary-pet',
    title: 'Positron emission tomography scan (PET) ',
    audio: positronEmissionAudio,
    text: 'A procedure where glucose is injected into a vein to identify cancer cells in the body through a scan.'
  },
  {
    id: 'glossary-rad-therapy',
    title: 'Radiation therapy ',
    audio: radiationAudio,
    text: 'High-energy radiation that comes from outside of the body that is used to kill cancer cells and shrink tumors.'
  },
  {
    id: 'glossary-squamous-cell',
    title: 'Squamous cell ',
    audio: squamosAudio,
    text: 'Flat cells located on the outer part of the epidermis that are constantly being shed as new cells form. '
  },
];

export default GlossaryList;