import React from 'react';

const Title = ({ className, text }) => {

  return (
    <section className={ `dse-title ${className || ''}` }>
      <h2 className='dse-title__text' dangerouslySetInnerHTML={{__html: text}}></h2>
    </section>
  )
}

export default Title
