import React from "react";
import DSEWrapper from "../DSEWrapper/DSEWrapper";
import desktopImg from "../../../assets/images/pages/dse-home/face-desk.png";
import mobImg from "../../../assets/images/pages/dse-home/face-mb.png";

const FaceParts = () => 
  <section className="dse-face-parts dse-solid-bg-face">
    <DSEWrapper fullWidth={true}>
      <picture>
        <source media="(min-width:768px)" srcSet={desktopImg} />
        <img className="dse-face-parts__img" src={mobImg} alt="Infographic describes the nasopharyngeal cancer tumor location" />
      </picture>
    </DSEWrapper>
  </section>

export default FaceParts;