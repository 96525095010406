import React from "react";
import { navigate } from "gatsby";
import DseCta from "../DSECta/DSECta";
import Ribbon from "../ribbon/ribbon";
import Title from "../title/title";
import DSEWrapper from "../DSEWrapper/DSEWrapper";

const HowTreated = () => {
  const clickHandler = () => {
    navigate('/join-the-community');
  };

  return (
    <section className="dse-how-treated dse-grad-bg5" id="how-is-npc-treated">
      <DSEWrapper className="dse-how-treated__title" fullWidth={true}>
        <Title text="HOW IS NPC TREATED?"/>
      </DSEWrapper>
      <DSEWrapper>
        <div className="dse-how-treated__container">
          <h3 className="dse-heading3 dse-light-text">What treatment options are available?</h3>
          <div className="dse-how-treated__text-container">
            <p>Your treatment plan for NPC may include a combination of various cancer treatments such as:</p>
            <ul>
              <li>Chemotherapy</li>
              <li>Radiation therapy</li>
              <li>Intensity-modulated radiation therapy (radiation therapy that uses 3D modeling to precisely deliver radiation to the tumor)</li>
            </ul>
          </div>
          <Ribbon className="dse-how-treated__ribbon">
            <p>Treatments beyond traditional chemotherapy and radiation therapy, such as immunotherapy, are also available to treat people with NPC</p>
          </Ribbon>
          <h3 className="dse-heading3 dse-light-text">Who is on my cancer care&nbsp;team?</h3>
          <div className="dse-how-treated__text-container">
            <p>Your care will be led by your oncologist, who is responsible for your treatment plan, but every member of your team plays an important role. Your team members may include:</p>
            <ul>
              <li>Medical oncologist (primary provider)</li>
              <li>Nurses</li>
              <li>Social workers</li>
              <li>Radiation oncologists</li>
              <li>Nurse navigators</li>
              <li>Nutritionists</li>
            </ul>
            <p>Your NPC journey will start by meeting with your healthcare provider to ask any questions you may have.</p>
          </div>
          <p className="dse-highlight-text">Uncover more information about your full cancer care team</p>
          <DseCta action={clickHandler} text="Join the community"/>
        </div>
      </DSEWrapper>
    </section>
  );
};

export default HowTreated;
